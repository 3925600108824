import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from './store';
import '@/config/element.config';
import { Flex } from '@/styleComponents/global/Flex';
import SvgIcon from '@/components/global/SvgIcon.vue';
import CountIn from '@/components/global/CountIn.vue';
import formCreate from "@form-create/element-ui";
import {Message} from 'element-ui';
import openModal  from './utils/Dialog';

Vue.component('Flex', Flex);
Vue.component('SvgIcon', SvgIcon);
Vue.component('CountIn',CountIn);
Vue.prototype.$openModal = openModal;
Vue.prototype.$Message = Message;
Vue.use(formCreate)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');


<Flex
  class="dataText"
  justify-content="space-between"
  direction="column"
>
  <slot v-if="isVNode" />
  <div v-else>
    <div class="getStatistics text">
      {{ text }}
    </div>
    <div
      class="getStatistics_num number"
      ref="getStatistics_num"
      :style="{ 'color': color }"
    >
      {{ startVal }}
    </div>
  </div>
</Flex>

<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import {useStorage, useTitle} from '@vueuse/core';
import {PublicApi} from "@/api/public";
import 'default-passive-events';
import {SysSettingApi} from "@/api/setting";
import {formatSysSetting} from "@/utils/FormatSysSetting";
import {useFavicon} from "@vueuse/core/index";
import {mapState, mapMutations} from 'vuex';

export default {
  data() {
    return {};
  },
  created() {
    this.getTenantHost();
  },
  computed: {
    ...mapState({
      member_login_common: state => state.member_login_common,
      base: state => state.base,
    }),
  },
    mounted() {

    },
    methods: {
    ...mapMutations(['setSystem']),
    
    getTenantHost() {
      console.log(window.location.hostname);
      let hostName =
        window.location.hostname === "localhost"
          ? //"mmds-metting.jscs.org.cn"
            "front-mmds.aihoge.com"
          : window.location.hostname;
      PublicApi.getTenantHost({source_host: hostName}).then(res => {
        useStorage('tenantInfo', res.result);
        this.getSysSetting('base', ['base_site_logo', 'base_site_title','base_site_favicon', 'base_site_main_page_map']);
        this.getSysSetting('member_login_common', ['member_login_common_logo']);
      });
    },
    getSysSetting(parentKey, childKey) {
      SysSettingApi.getSysSetting(parentKey).then(res => {
          childKey.forEach(item=>{
              this.setSystem({
                  parentKey,
                  item,
                  value: formatSysSetting(res.result[parentKey], item)
              });
              if (item === 'base_site_title') {
                  const title = useTitle();
                  title.value = this.base.base_site_title;
              }
              if (item === 'base_site_favicon') {
                  const icon = useFavicon();
                  icon.value = !this.$store.getters.isKeYuan && this.base.base_site_favicon;
              }
          });

      });
    },
  }
};

</script>
<style lang='scss'>
.is-hidden {
  display: table-cell
}

body, html {
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}
</style>

<template>
  <section class="container">
    <!--最外层容器-->
    <Header />
    <Flex class="left-aside">
      <section class="menu">
        <!--左边的容器-->
        <Menu :list="list" />
      </section>
      <section>
        <Breadcrumb />
        <div class="content-container">
          <router-view />
        </div>
      </section>
    </Flex>
  </section>
</template>
<script>
import Menu from "./Menu.vue";
import Header from "./Header.vue";
import unitMenu from "@/config/member/unitMenu.json";
import personalMenu from "@/config/member/personalMenu.json";
import memberManage from '@/config/memberManage/menu.json';
import Breadcrumb from "@/components/global/Breadcrumb.vue";

import {PublicApi} from "@/api/public";
import {SysSettingApi} from "@/api/setting";
import {formatSysSetting} from "@/utils/FormatSysSetting";
import {mapMutations, mapState} from "vuex";
import {useFavicon, useTitle} from "@vueuse/core";

export default {
  data() {
    return {
      params: new Map([
        ['Member', ['memberStatus', 'memberType', 'jobLevel', 'educationalLevel', 'employerType', 'politics']],
        ['Attachment', ['type']],
        ['Order', ['orderStatus', 'payStatus', 'payType']],
      ]),
      Member: {},
      Attachment: {},
      Order: {}
    };
  },
  mounted() {
    !this.orderList.length && this.getSysSetting('order_pay', ['order_pay_list', 'order_pay_off']);
    //  循环获取模块类型
    for (let [key, value] of this.params) {
      this.getModuleType(key, value);
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    ...mapState({
      orderList: state => state.order_pay.order_pay_list
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    list() {
      const path = this.$route.path.split('/')[1];
      // 根据路由获取对应的菜单\
      if (path === 'member') {
        const memberTypeID = JSON.parse(localStorage.getItem('contactInfo')).memberType;
        // 单位会员id 420888525551112192
        if (memberTypeID === '420888525551112192') {
          // 单位会员菜单
          return unitMenu.list;
        } else {
          // 企业会员菜单
          return personalMenu.list;
        }
      } else if (path === 'memberManage') {
        const permission_list = JSON.parse(localStorage.getItem("memberManageUserInfo")).permission_list;
        return this.generateManageList(permission_list);
      }
    }
  },
  components: {
    Menu,
    Header,
    Breadcrumb
  },
  methods: {
    ...mapMutations(['setSystem']),
    getModuleType(module, type) {
      PublicApi.getEnumList({keys: type}, module).then(res => {
        this.params.get(module).forEach((item) => {
          this[module][item] = res.result[item];
        });
        localStorage.setItem(module, JSON.stringify(this[module]));
      });
    },
    getSysSetting(parentKey, childKey) {
      SysSettingApi.getSysSetting(parentKey).then(res => {
        childKey.forEach(item => {
          this.setSystem({
            parentKey,
            item,
            value: formatSysSetting(res.result[parentKey], item)
          });
          if (item === 'base_site_title') {
            const title = useTitle();
            title.value = this.base.base_site_title;
          }
          if (item === 'base_site_favicon') {
            const icon = useFavicon();
            icon.value =  !this.$store.getters.isKeYuan && this.base.base_site_favicon;
          }
        });

      });
    },
    generateManageList(permission_list = []) {
      return permission_list
        .sort((a, b) => a.sort - b.sort)
        .map(item => {
          let listItem = {
            title: item.display_name,
            routeName: item.path,
            ...JSON.parse(item.config_value || null),
          };
          if (item.children && item.children.length > 0) {
            listItem.children = this.generateManageList(item.children);
          }
          return listItem;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  width: calc(100vw - 200px);
  height: calc(100vh - 114px);
  overflow-y: auto;
  background-color: #fff;
  padding: 0 30px 30px;
  box-sizing: border-box;
}
</style>

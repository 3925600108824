<template>
  <div>
    <el-aside
      width="200px"
    >
      <el-menu
        background-color="#F9FAFC"
        text-color="#333"
        active-text-color="#fff"
        :default-active="currentActive"
        unique-opened
        @select="select"
      >
        <template v-for="(item, index) in list">
          <el-submenu
            v-if="item.children"
            :key="index"
            :index="item.routeName"
          >
            <template slot="title">
              <SvgIcon
                :name="item.active ? item.iconHover : item.icon"
                :width="17"
                :height="17"
                class="icon"
              />
              <span :style="{color:item.active ?'#2468F2':'#333333'}">{{ item.title }}</span>
            </template>
            <template v-if="item.children">
              <el-menu-item-group
                v-for="(cItem, cIndex) in item.children"
                :key="cIndex + 'children'"
              >
                <el-menu-item :index="`${cItem.routeName}`">
                  <span>{{ cItem.title }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </template>
          </el-submenu>
          <el-menu-item
            v-else
            :index="`${item.routeName}`"
          >
            <SvgIcon
              :name="currentActive == item.routeName ? item.iconHover : item.icon"
              :width="17"
              :height="17"
              class="icon"
            />
            <span>{{ item.title }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    currentActive() {
      return  this.$route.name.toString();
    }
  },
  mounted() {
  },
  methods: {
    select(e) {
      this.list.forEach(item=>{
        this.$set(item,'active',0);
      });
      this.$set(this.list[e.includes('Audit')?1:0], 'active', 1);
      this.$router.push({name: e});
    }
  }
};

</script>
<style lang="scss">
.el-aside {
  height: calc(100vh - 60px);

  .el-menu {
    height: 100%;
    box-shadow: -1px 0px 0px 0px #e8ebf0 inset;
    color: #333;

    .el-submenu {
      margin-left: 10px;

      .el-submenu__title {
        width: 180px;
        height: 40px;
        border-radius: 6px;
        font-size: 14px;
        margin-top: 10px;
        display: flex;
        align-items: center;
      }

      .icon {
        margin-right: 14px;
      }
    }

    .el-menu-item {
      width: 180px;
      height: 40px;
      border-radius: 6px;
      font-size: 14px;
      margin: 10px 0 10px 10px;
      display: flex;
      align-items: center;
      min-width: unset !important;

      &.is-active {
        background-color: #2468F2 !important;
      }

      .icon {
        margin-right: 14px;
      }
    }

    .el-menu-item-group {
      .el-menu-item {
        margin: 0 !important;
        line-height: 60px;
        //padding-left: 30px !important;
      }
    }
  }

}
</style>

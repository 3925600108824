import { Loading } from 'element-ui';

/**
 * @description 通过当前浏览器地址获取接口baseUrl
 * */
function getBaseUrl() {
    let baseUrl = 'https://mmds-dev.aihoge.com';
    let urlMap = new Map([
        ['localhost', 'https://mmds.aihoge.com'],
        ['front-mmds.aihoge.com', 'https://mmds.aihoge.com'],
        ['front-mmds-dev.aihoge.com', 'https://mmds-dev.aihoge.com'],
        ['front-mmds-zhaoyou.hogeos.com', 'http://mmds-zhaoyou.hogeos.com'], //招油网
        ['member.jscs.org.cn', 'https://mmds.jscs.org.cn'],
        ['stachina-front.aihoge.com', 'https://stachina.aihoge.com'], //科促会
        ['jszyys-front.aihoge.com', 'https://jszyys.aihoge.com'],
        ['meeting-dev-kaku.aihoge.com', 'http://mmds-dev-kaku.aihoge.com'], // 卡酷测试环境
    ]);
    for (let key of urlMap.keys()) {
        if (window.location.host.includes(key)) {
            baseUrl = urlMap.get(key);
        }
    }
    return baseUrl;
    // return 'https://mmds-dev.aihoge.com';
}

/**
 * @description 动态添加headers
 * */
export const formatToken = (() => {
    let type = window.location.pathname.split('/')[1]; //当前系统 member or memberManage
    let token = localStorage.getItem(`${type}UserInfo`) !== 'undefined' && JSON.parse(localStorage.getItem(`${type}UserInfo`))?.headers;
    return { [type === 'member' ? 'Member-User-Authorization' : 'Mmds-Tenant-Authorization']: token };
});


/**
 * @description 区分租户和非租户请求地址
 * */
export const formatUrl = (config => {
    // let tenantUrl = '';
    // let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    // let isTenant = !config.noTenant;
    // // 判断本地是否有租户信息
    // if (tenantInfo && tenantInfo['hosts_simple']?.length) {
    //     // 判断生产环节 开发环境切换请求地址
    //     let devUrl = tenantInfo['hosts_simple'].filter(item => item['is_dev'] && item['host_type'] === 2);
    //     let proUrl = tenantInfo['hosts_simple'].filter(item => !item['is_dev'] && item['host_type'] === 2);
    //     tenantUrl = import.meta.env.PROD ? `http${proUrl[0].is_https ? 's' : ''}://${proUrl[0].host}` : `http://${devUrl[0].host}`;
    //     // tenantUrl = import.meta.env.PROD ? `http://${devUrl[0].host}` : `http://${proUrl[0].host}`;
    // }
    // // return (isTenant ? tenantUrl || getBaseUrl() : getBaseUrl()) + config.url;
    return getBaseUrl()+ config.url;
});

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
    });
}

function endLoading() {
    loading.close();
}

let needLoadingRequestCount = 0;
let loading;
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        endLoading();
    }
}



<svg
  class="svg-icon"
  :ref="`${name}`"
  :style="{
    width: width + 'px',
    height: height + 'px',
    color: color
  }"
  @mousedown="clickIcon"
>
  <use
    :xlink:href="`#icon-${name}`"
    :fill="color"
  />
</svg>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Layouts from '@/components/global/Layouts.vue';
import {
    loadMemberView,
    loadManageView
} from './addRoute.js';
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// 注册路由插件
Vue.use(VueRouter);
const routes = [{
        path: '/', //访问路径
        redirect: '/member/login',
        component: loadMemberView(),
        name: 'member',
        children: [{
                path: '/member/system',
                name: 'memberLayouts',
                component: Layouts,
                children: [

                    {
                        //JSCS会员
                        path: '/member/system/Jscsmember',
                        name: 'memberJscs',
                        component: loadMemberView('/Jscsmember'),
                        meta: {
                            title: '会员认证'
                        }
                    },
                    {
                        //JSCS会费
                        path: '/member/system/Membershipfee',
                        name: 'memberShipFee',
                        component: loadMemberView('/Membershipfee'),
                    },
                    {
                        //JSCS专委会
                        path: '/member/system/specialCommittee',
                        name: 'SpecialCommittee',
                        component: loadMemberView('/Specialcommittee'),
                        redirect: '/member/system/specialCommittee/special',
                        meta: {
                            title: '专委会'
                        },
                        children: [{
                                path: '/member/system/specialCommittee/specialAudit',
                                name: 'SpecialAudit',
                                component: loadMemberView('/Specialcommittee/SpecialAudit'),
                                meta: {
                                    title: '专委会申请'
                                },
                            },
                            {
                                path: '/member/system/specialCommittee/special',
                                name: 'Special',
                                component: loadMemberView('/Specialcommittee/Special'),
                                meta: {
                                    title: '专委会'
                                },
                            },
                            {
                                path: '/member/system/specialCommittee/auditDetail',
                                name: 'AuditDetail',
                                component: loadMemberView('/Specialcommittee/AuditDetail'),
                                meta: {
                                    title: '审核详情'
                                },
                            },
                        ]
                    },
                    {
                        //会员级别
                        path: '/member/system/Memberlevel',
                        name: 'memberLevelSync',
                        component: loadMemberView('/Memberlevel'),
                    },
                    {
                        //会员单位
                        path: '/member/system/Memberassociate',
                        name: 'memberAssociation',
                        component: loadMemberView('/Memberassociate'),
                        meta: {
                            title: '会员单位'
                        }
                    },
                    {
                        //会员单位申请
                        path: '/member/system/Memberassociate/applyAudit',
                        name: 'applyAudit',
                        component: loadMemberView('/Memberassociate/applyAudit'),
                        meta: {
                            title: '会员单位申请'
                        }
                    },
                    {
                        //个人信息
                        path: '/member/system/Personalinfo',
                        name: 'personalInformation',
                        component: loadMemberView('/Personalinfo'),
                        meta: {
                            title: '个人信息'
                        }
                    },
                    {
                        //个人信息详情修改
                        path: '/member/system/ModificationPersonal',
                        name: 'personalModify',
                        component: loadMemberView('/ModificationPersonal'),
                        meta: {
                            active: 'personalInformation',
                            title: '个人信息修改'
                        }
                    }

                ],
            },
            {
                path: '/member/login',
                name: 'memberLogin',
                component: loadMemberView('/Login')
            },
            {
                path: '/member/home',
                name: 'memberHome',
                component: loadMemberView('/Home')
            }
        ]
    },
    {
        path: '/memberManage', //访问路径
        redirect: '/memberManage/login',
        component: loadManageView(),
        children: [{
                path: '/memberManage/system',
                name: 'memberManageLayouts',
                component: Layouts,
                children: [{
                        path: '/memberManage/system/Member',
                        name: 'memberManageMember',
                        component: loadManageView(),
                        redirect: '/memberManage/system/Member/Formal',
                        meta: {
                            title: '会员管理'
                        },
                        children: [{
                                //正式会员
                                path: '/memberManage/system/Member/Formal',
                                name: 'memberManageFormal',
                                component: loadManageView('/Member/Formal'),
                                meta: {
                                    active: 'memberManageFormal',
                                    title: '个人会员'
                                }
                            },
                            { //会员单位管理
                                path: '/memberManage/system/company',
                                name: 'memberManageCompany',
                                component: loadManageView('/Company'),
                                meta: {
                                    active: 'memberManageCompany',
                                    title: '企业会员'
                                }
                            },
                            {
                                //会员信息详情
                                path: '/memberManage/system/Member/FormalDetail/MemberDetail',
                                name: 'memberDetail',
                                component: loadManageView('/Member/FormalDetail/MemberDetail'),
                                meta: {
                                    title: '会员信息'
                                },
                            },
                            {
                                //编辑会员信息详情
                                path: '/memberManage/system/Member/FormalDetail/OperateMember',
                                name: 'editMemberDetail',
                                component: loadManageView('/Member/FormalDetail/OperateMember'),
                            },
                            {
                                //普通会员
                                path: '/memberManage/system/Member/Normal',
                                name: 'memberManageNormal',
                                component: loadManageView('/Member/Normal'),
                                meta: {
                                    active: 'memberManageNormal',
                                }
                            },
                            { //会员单位管理-会员信息
                                path: '/memberManage/system/companyMember',
                                name: 'memberManageCompanyMember',
                                component: loadManageView('/CompanyMember'),
                                meta: {
                                    title: '企业会员详情',
                                }
                            },
                            { //会员单位管理-单位信息
                                path: '/memberManage/system/companyOrganize',
                                name: 'memberManageCompanyOrganize',
                                component: loadManageView('/CompanyOrganize'),
                                meta: {
                                    title: '企业会员详情'
                                }
                            },
                            { //会员单位管理-添加会员单位
                                path: '/memberManage/system/AddCompanyOrganize',
                                name: 'memberManageAddCompanyOrganize',
                                component: loadManageView('/AddCompanyOrganize'),
                                meta: {
                                    active: 'memberManageCompany',
                                    title: '编辑企业会员'
                                }
                            },
                        ]
                    },
                    {
                        path: '/memberManage/system/organize',
                        name: 'memberManageOrganize',
                        component: loadManageView('/Organize'),
                        meta: {
                            title: '学会组织管理'
                        }
                    },
                    {
                        path: '/memberManage/system/professional',
                        name: 'Professional',
                        component: loadManageView('/Professional'),
                        redirect: '/memberManage/system/specialManage',
                        meta: {
                            title: '专业委员会'
                        },
                        children: [
                            {
                            path: '/memberManage/system/specialManage',
                            name: 'SpecialManage',
                            component: loadManageView('/Professional/SpecialManage'),
                            meta: {
                                  title: '专委会管理'
                                },
                            },
                            {
                            path: '/memberManage/system/joinAudit',
                            name: 'JoinAudit',
                            component: loadManageView('/Professional/JoinAudit'),
                            meta: {
                                  title: '入会审核'
                                },
                            }, 
                            {
                            path: '/memberManage/system/personManage',
                            name: 'PersonManage',
                            component: loadManageView('/Professional/PersonManage'),
                            meta: {
                                  title: '人员管理'
                                },
                            }, 
                            {
                            path: '/memberManage/system/manageAuditDetail',
                            name: 'ManageAuditDetail',
                            component: loadManageView('/Professional/AuditDetail'),
                            meta: {
                                  title: '审核详情'
                                },
                            }, 
                            {
                            path: '/memberManage/system/checkMember',
                            name: 'CheckMember',
                            component: loadManageView('/Professional/CheckMember'),
                            meta: {
                                  title: '会员详情'
                                },
                            }, 
                    ]
                    },
                    // 会员资格审核
                    {
                        path: '/memberManage/system/Audit',
                        component: loadManageView(),
                        redirect: '/memberManage/system/Audit',
                        name: 'examine',
                        meta: {
                            title: '认证审核'
                        },
                        children: [{
                                path: '/memberManage/system/Audit',
                                name: 'Audit',
                                component: loadManageView('/Audit'),
                                meta: {
                                    active: 'Audit',
                                    title: '会员资格审核'
                                },
                            },
                            // 会员单位资格审核
                            {
                                path: '/memberManage/system/CompanyAudit',
                                name: 'CompanyAudit',
                                component: loadManageView('/CompanyAudit'),
                                meta: {
                                    title: '企业会员审核'
                                },
                            },
                            // 会员资格审核详情页
                            {
                                path: '/memberManage/system/AuditDetail',
                                name: 'memberManageAuditDetail',
                                component: loadManageView('/AuditDetail'),
                                meta: {
                                    active: 'memberManageAuditDetail',
                                    title: '会员资格审核详情'
                                },
                            },

                            // 单位资格审核详情
                            {
                                path: '/memberManage/system/CompanyAudit/detail',
                                name: 'memberManageCompanyAuditDetail',
                                component: loadManageView('/CompanyAudit/detail'),
                                meta: {
                                    active: 'memberManageCompanyAuditDetail',
                                    title: '企业会员审核详情'
                                },
                            },
                        ]
                    },
                    {
                        path: '/memberManage/system/Order',
                        name: 'memberManageOrder',
                        component: loadManageView('/Order'),
                        meta: {
                            title: '会员订单'
                        },
                    },
                    {
                        path: '/memberManage/system/Order/detail',
                        name: 'memberManageOrderDetail',
                        component: loadManageView('/Order/detail'),
                        meta: {
                            title: '订单详情页'
                        }
                    },
                    {
                        path: '/memberManage/system/expenses',
                        name: 'memberManageExpenses',
                        component: loadManageView('/Expenses'),
                        meta: {
                            title: '会费管理'
                        }
                    },
                    // 会员通知
                    {
                        path: '/memberManage/system/notice',
                        name: 'memberManageNotice',
                        component: loadManageView('/Notice'),
                        meta: {},
                        children: [{
                                path: '/memberManage/system/notice/sendMessage',
                                name: 'memberManageSendMessage',
                                component: loadManageView('/Notice/sendMessage'),
                                meta: {
                                    active: 'memberManageNotice',
                                    is_child: true,
                                    floor: 2,
                                    title: '发送短信通知'
                                }
                            },
                            {
                                path: '/memberManage/system/notice/messageSignatures',
                                name: 'memberManageMessageSignatures',
                                component: loadManageView('/Notice/messageSignatures'),
                                meta: {
                                    active: 'memberManageNotice',
                                    is_child: true,
                                    floor: 2,
                                    title: '短信签名'
                                }
                            }
                        ]
                    }
                ],
            },
            {
                path: '/memberManage/login',
                name: 'memberManageLogin',
                component: loadManageView('/Login')
            },
            {
                path: '/memberManage/home',
                name: 'memberManageHome',
                component: loadManageView('/Home')
            }
        ]
    }
];
const router = new VueRouter({
    // 暂时先用不使用history路由，否则打包之后css,js文件访问不到
    mode: 'history',
    routes
});
router.beforeEach(async (to, from, next) => {
    const type = to.path.split('/')[1];
    NProgress.start();
    if (!localStorage.getItem(`${type}UserInfo`) && to.name !== `${type}Login`) {
        next({
            name: `${type}Login`,
        });
    } else {
        next();
    }
    // start progress bar
    setTimeout(() => {
        NProgress.done();
    });
});
router.afterEach(() => {
    // finish progress bar
    NProgress.done();
});
export default router;
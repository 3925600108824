
<Flex
  class="header"
  align-item="center"
  justify-content="space-between"
  :style="{ 'background': bgColor}"
>
  <Flex
    class="logo"
    align-item="center"
  >
    <img
      :src="member_login_common.member_login_common_logo"
      alt=""
      class="logo_text"
      v-if="!$store.getters.isKeYuan"
    >
    <span
      class="vip"
      v-if="title"
    >{{ title }}</span>
  </Flex>
  <Flex
    class="right"
    align-item="center"
  >
    <el-avatar
      class="user_img"
      :src="avatar"
      @error="errorHandler"
    />
    <el-dropdown>
      <span class="el-dropdown-link">
        {{ real_name || member_mobile || userInfo.account }}<i class="el-icon-arrow-down el-icon--right" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="loginOut()">
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </Flex>
</Flex>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        count: 0,
        base: {
            base_site_logo: '',
            base_site_title: '',
            base_site_favicon: '',
            base_site_main_page_map: []
        },
        member_login_common: {
            member_login_common_logo: '',
        },
        order_pay: {
            order_pay_list: []
        }
    },
    getters: {
        count(state) {
            return state.count;
        },
        isKeYuan() {
			return window.location.host.includes('front-mmds') || window.location.host.includes('local');
		}
    },
    mutations: {
        addCount(state, num) {
            state.count += num;
        },
        setSystem(state, data) {
            let {parentKey, item, value} = data;
            state[parentKey][item] = value;
        }
    },
    actions: {}
});

<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="item in breadList"
        :to="item.path"
        :key="item.name"
      >
        {{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadList: []
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  computed:{
    // 判断是否为专委会账号 ，专委会账号 禁止点击面包屑
    isCommittee(){
      return JSON.parse(localStorage.getItem('memberManageUserInfo')).role.some(ele=>ele.name==='committee');
    }
  },  
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name);
      this.breadList = matched.filter(item => item.name !== "memberLayouts" && item.name !== 'memberManageLayouts' && item.name !== 'member' && item.name !== 'manageLogin');
      if(this.isCommittee){
        this.breadList[0].path = '/memberManage/system/personManage';
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 100%;
  height: 54px;
  background: #f9fafc;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  padding-left: 30px;
  box-sizing: border-box;
}
</style>

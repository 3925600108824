
<el-dialog
  :class="['modal', hide ? 'box-hide' : '']"
  :visible.sync="DialogVisible"
  :width="width"
  :custom-class="customClass"
  :close-on-click-modal="false"
>
  <div
    slot="title"
    class="header-title"
  >
    <span class="title-name">{{ title }}</span>
  </div>
  <component
    :ref="`${name}-modal`"
    :is="name"
    v-if="name"
    :params.sync="params"
    :confirm-disabled.sync="confirmDisabled"
    :member-num.sync="memberNum"
    :dialog-visible="DialogVisible"
    :visible.sync="DialogVisible"
    :resolve="resolve"
  />
  <Flex
    slot="footer"
    class="dialog-footer"
    :justify-content="position"
    v-if="footer.show"
  >
    <el-button
      class="cancel"
      @click="cancel()"
      v-if="cancelSetting.show"
    >
      {{ cancelSetting.title }}
    </el-button>
    <el-button
      :disabled="confirmDisabled"
      :class="['confirm', { 'confirm-disabled': confirmDisabled }]"
      type="primary"
      v-if="confirmSetting.show"
      @click="confirm()"
    >
      {{ confirmSetting.title }}
      <span v-if="memberNum && !confirmDisabled && params.level == 1">({{ memberNum }})</span>
    </el-button>
  </Flex>
</el-dialog>

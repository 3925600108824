import instance from "@/api/serve";

export const MemberAuthApi = {
    //  获取用户信息
    getPersonalInfo() {
        return instance({
            url: '/mesh/member-center/member/member',
        });
    },

    //查询会员状态
    getMemberStatus() {
        return instance({
            url: '/mesh/member-center/member/card/status'
        });
    },

    //个人会员申请
    applyPersonalMember(data) {
        return instance({
            url: '/mesh/member-center/flow/memberType/apply',
            method: 'post',
            data
        });
    },

    //查询会员单位接口
    getCompanyMemberList() {
        return instance({
            url: '/mesh/member-center/company/companies/simple'
        });
    },

    // 更新个人信息
    updatePersonalInfo(data){
        return instance({
            url:'/mesh/member-center/member/member',
            method:'put',
            data
        });
    },

    //  获取会员类型
    getMemberTypeList(data){
        return instance({
            url: '/mesh/config-center/member/auth/types',
            data
        })
    },

    //  获取会员类型详情
    getMemberTypeDetail(id){
        return instance({
            url: `/mesh/config-center/member/auth/type/${id}`
        })
    }

};

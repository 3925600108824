<template>
  <Flex
    class="header"
    align-item="center"
    justify-content="space-between"
    :style="{ 'background': bgColor}"
  >
    <Flex
      class="logo"
      align-item="center"
    >
      <img
        :src="member_login_common.member_login_common_logo"
        alt=""
        class="logo_text"
        v-if="!$store.getters.isKeYuan"
      >
      <span
        class="vip"
        v-if="title"
      >{{ title }}</span>
    </Flex>
    <Flex
      class="right"
      align-item="center"
    >
      <el-avatar
        class="user_img"
        :src="avatar"
        @error="errorHandler"
      />
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ real_name || member_mobile || userInfo.account }}<i class="el-icon-arrow-down el-icon--right" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="loginOut()">
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </Flex>
  </Flex>
</template>

<script>
import Worker from '../../work/index?url';
import { MemberAuthApi } from "@/api/member/memberAuth";
import { mapState } from 'vuex';
import { PublicApi } from "@/api/public";

export default {
    data() {
        return {
            type: this.$route.path.split('/')[1],
            data: null,
            avatar: new URL('../../assets/images/sm_avatar.png', import.meta.url).href,
            real_name: '',
            member_avatar: '',
            member_mobile: '',
            id: '',
            worker: null
        };
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        bgColor: {
            type: String,
            default: "#2468f2"
        }
    },
    created() {
        if ( this.type === 'member' && !this.id ) {
            this.getPersonalInfo();
        }
        this.worker = new SharedWorker(Worker);
        this.worker.port.addEventListener('message', (e) => {
            const data = JSON.parse(e.data);
            if ( data.type === 'loginOut' ) {
                if ( this.userInfo.account === data.userInfo.account ) {
                    localStorage.removeItem(`${this.type}UserInfo`);
                    this.$router.push({
                        name: `${this.type}Login`
                    });
                }
            }
        }, false);
        this.worker.port.start();
    },
    computed: {
        ...mapState({
            member_login_common: state => state.member_login_common,
            base: state => state.base,
        }),
        userInfo() {
            return JSON.parse(localStorage.getItem(`${this.type}UserInfo`)) || {};
        },
    },
    methods: {
        errorHandler(e) {
            console.log('头像', e);
        },
        loginOut() {
            if ( this.type === 'member' ) {
                PublicApi.memberSignOut().then(res => {
                    localStorage.removeItem(`${this.type}UserInfo`);
                    localStorage.removeItem(`tenantInfo`);
                    this.$router.push({
                        name: `${this.type}Login`
                    });
                });
            }else{
                PublicApi.tenantLogOut().then(res => {
                    localStorage.removeItem(`${this.type}UserInfo`);
                    localStorage.removeItem(`tenantInfo`);
                    this.$router.push({
                        name: `${this.type}Login`
                    });
                });
            }

            let message = {
                type: 'loginOut',
                userInfo: this.userInfo
            };
            message = JSON.stringify(message);
            this.worker.port.postMessage(message);
        },
        getPersonalInfo() {
            MemberAuthApi.getPersonalInfo().then(res => {
                if ( res.error_code === 0 ) {
                    this.data = res.result;
                }
                this.avatar = this.data.member_avatar || new URL('../../assets/images/sm_avatar.png', import.meta.url).href;
                this.real_name = this.data?.info?.real_name ?? '';
                this.member_avatar = this.data.member_avatar;
                this.member_mobile = this.data.member_mobile || this.userInfo.account;
                this.id = this.data.id;
            });
        },
    },
};

</script>
<style lang='scss'>
.header {
    height: 60px;
    width: 100%;

    .logo {
        height: 100%;
        margin-left: 25px;
    }

    .logo_left {
        width: 36px;
        height: 36px;
        margin-right: 6.8px;
    }

    .logo_text {
        height: 30px;
    }

    .vip {
        width: 106px;
        height: 32px;
        background: url("~@/assets/images/vip@2x.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 46.5px;
        line-height: 32px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        padding-left: 16px;
    }

    .right {
        margin-right: 25px;

        .user_img {
            margin-right: 12.5px;
            width: 28px;
            height: 28px;
        }

        .el-dropdown-link {
            cursor: pointer;
            color: #409EFF;
        }

        .el-icon-arrow-down {
            font-size: 12px;
        }
    }
}
</style>


<div>
  <el-aside
    width="200px"
  >
    <el-menu
      background-color="#F9FAFC"
      text-color="#333"
      active-text-color="#fff"
      :default-active="currentActive"
      unique-opened
      @select="select"
    >
      <template v-for="(item, index) in list">
        <el-submenu
          v-if="item.children"
          :key="index"
          :index="item.routeName"
        >
          <template slot="title">
            <SvgIcon
              :name="item.active ? item.iconHover : item.icon"
              :width="17"
              :height="17"
              class="icon"
            />
            <span :style="{color:item.active ?'#2468F2':'#333333'}">{{ item.title }}</span>
          </template>
          <template v-if="item.children">
            <el-menu-item-group
              v-for="(cItem, cIndex) in item.children"
              :key="cIndex + 'children'"
            >
              <el-menu-item :index="`${cItem.routeName}`">
                <span>{{ cItem.title }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </template>
        </el-submenu>
        <el-menu-item
          v-else
          :index="`${item.routeName}`"
        >
          <SvgIcon
            :name="currentActive == item.routeName ? item.iconHover : item.icon"
            :width="17"
            :height="17"
            class="icon"
          />
          <span>{{ item.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </el-aside>
</div>

import instance from "@/api/serve";


/**
 * @description: 获取用户信息
 * @param {type}
 */
export const SysSettingApi = {
    getSysSetting: (category_key) => {
        return instance({
            url: `/mesh/config-center/config/${category_key}/items`,
            method: "get"
        });
    }
};
import instance from '../serve';

export const PublicApi = {
    // 文件上传
    normalFileUpload(data) {
        return instance({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'post',
            url: '/mesh/member-center/common/attachment/upload',
            data
        });

    },

    // 租户文件上传
    tenantFileUpload(data) {
        return instance({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'post',
            url: '/mesh/member-center/tenant/common/attachment/upload',
            data
        });

    },

    //  租户登陆
    tenantLogin(data) {
        return instance({
            url: '/auth/signIn',
            method: 'post',
            data
        });
    },

    //  租户登陆
    tenantLogOut() {
        return instance({
            url: '/auth/signOut',
            method: 'post'
        });
    },

    //  获取租户信息
    getTenantInfo() {
        return instance({
            url: '/tenant/info'
        });
    },

    //  用户登陆
    memberSignIn(data) {
        return instance({
            url: '/mesh/member-center/auth/signIn',
            method: 'post',
            data
        });
    },

    //  用户登陆
    memberSignOut() {
        return instance({
            url: '/mesh/member-center/auth/signOut',
            method: 'post'
        });
    },

    //获取手机验证码
    getPhoneCode(data) {
        return instance({
            url: '/mesh/member-center/auth/mobile/code',
            method: 'post',
            data
        });
    },

    //  获取枚举值
    getEnumList(data,module) {
        return instance({
            url: `/mesh/member-center/common/enum/${module}`,
            method: 'post',
            hideLoading: true,
            data
        });
    },

    // 获取手机 邮箱验证码
    getVerificationCode(data,entity){
        return instance({
            url:`/mesh/member-center/verify/code/${entity}`,
            method:'POST',
            data
        });

    },

    // 验证码验证
    verificationCode(data,entity){
        return instance({
            url:`/mesh/member-center/verify/${entity}`,
            method:'PUT',
            data
        });
    },

    // 修改手机号
    changeBindMobile(data){
        return instance({
            url:`/mesh/member-center/member/info/unbind/mobile`,
            method: 'PUT',
            data
        });
    },

    //  获取租户地址
    getTenantHost(data){
        return instance({
            url: `/tenant/hostMap`,
            data,
            noTenant: true
        });
    },
    // 用户注册
    register(data){
        return instance({
            url: `/mesh/member-center/auth/register`,
            method:'post',
            data,
        });
    },
};

import axios from 'axios';
import { Message } from 'element-ui';
import Qs from "qs";
import { Loading } from 'element-ui';

import { formatUrl, formatToken, showFullScreenLoading, tryHideFullScreenLoading } from '@/utils/FormatAxios';

const instance = axios.create({
  timeout: 10000,
  credentials: false,
});
// http request 拦截器
// 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
instance.interceptors.request.use(
  config => {
    config.headers = Object.assign({
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      ...formatToken() // 格式化token
    }, config.headers);
    // 格式化url
    config.url =config.type==='test'?'http://10.0.29.103:9500'+ config.url: formatUrl(config);
    // 设置跨域
    config.withCredentials = false; 
    // 格式化参数
    if ( config.method === 'get' ) {
      config.paramsSerializer = (() => Qs.stringify(config.params));
      // 校验是否为get 传参改为params
      config.params = config.data;
    }
    if ( !config.hideLoading ) {
      showFullScreenLoading();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });
// http response 拦截器
instance.interceptors.response.use(
  response => {
    //拦截响应，做统一处理
    tryHideFullScreenLoading();
    let { error_message, error_code } = response.data;
    if ( error_code === 7007 ) {
      Message({
        message: 'token 失效，请重新登录！',
        type: 'error',
        duration: 1000 
      });
     setTimeout(() => {
      localStorage.clear();
      window.location.href = "/memberManage/login";
     }, 1000);
    } else if ( error_code !== 0 && error_code!== 7005 && error_message ) {
      Message({
        message: error_message,
        type: 'error',
        duration: 1000
      });
      return Promise.reject(response.data);
    }
    return response.data;
  },

  //接口错误状态处理，也就是说无响应时的处理
  error => {
    tryHideFullScreenLoading();
    // let { error_message, error_code } = response.data;
    // if ( error_code && error_message ) {
    //   Message({
    //     message: error_message,
    //     type: 'error',
    //     duration: 1000
    //   });
    // }
    throw new Error(error);// 返回接口返回的错误信息
  });
export default instance;

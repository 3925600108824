
<section class="container">
  <!--最外层容器-->
  <Header />
  <Flex class="left-aside">
    <section class="menu">
      <!--左边的容器-->
      <Menu :list="list" />
    </section>
    <section>
      <Breadcrumb />
      <div class="content-container">
        <router-view />
      </div>
    </section>
  </Flex>
</section>

<template>
  <Flex
    class="dataText"
    justify-content="space-between"
    direction="column"
  >
    <slot v-if="isVNode" />
    <div v-else>
      <div class="getStatistics text">
        {{ text }}
      </div>
      <div
        class="getStatistics_num number"
        ref="getStatistics_num"
        :style="{ 'color': color }"
      >
        {{ startVal }}
      </div>
    </div>
  </Flex>
</template>
<script>
import anime from 'animejs/lib/anime.es.js';
export default {
  props: {
    startVal: {
      type: [Number],
      default: 0
    },
    endVal: {
      type: [Number],
      default: 0
    },
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#2468f2'
    }
  },
  data() {
    return {

    };
  },
  computed: {
    // 是否是虚拟DOM
    isVNode () {
      return this.$slots.default;
    },
    el() {
      return this.isVNode ? this.$slots.default[1].elm : this.$refs.getStatistics_num;
    },
  },
  methods: {
   scrollNum () {
     anime({
      targets: this.el ,
      easing: 'easeInOutSine',
      round: 1
    });
   }
  },
  created() {},
  mounted() {
    this.scrollNum();
  },
  watch: {
    endVal(val) {
      this.scrollNum();
    }
  }
};
</script>
<style lang="scss" scoped>
  .dataText {
    margin-right: 7vw;
    .text {
        font-size: 14px;
        color: #999999;
        padding-bottom: 16px;
    }
    .number {
        font-size: 24px;
        font-weight: 500;
        color: #2468f2;
    }
}
</style>

export const loadMemberView = (path = '') => {
    const basePath = '../views/member' + path + '/index.vue';
    const modules = import.meta.glob("../views/member/**/**.vue");
    return modules[basePath];
};
export const loadManageView = (path = '') => {
    const basePath = '../views/memberManage' + path + '/index.vue';
    const modules = import.meta.glob("../views/memberManage/**/**.vue");
    return modules[basePath];
};
